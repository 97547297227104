import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { getEnvironment, initializeSentry } from '@shared/services';

import packageInfo from '../package.json';
import { PortalClientAppComponent } from './app/portal-client-app.component';
import { appConfig } from './app/portal-client-app.config';

initializeSentry('rocket-portal@' + packageInfo.version);

if (getEnvironment().production) {
  enableProdMode();
}

bootstrapApplication(PortalClientAppComponent, appConfig).catch((err) => console.error(err));
