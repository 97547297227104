{
  "name": "portal-client",
  "version": "5.11.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --hmr",
    "start:no-hmr": "ng serve",
    "build": "ng build",
    "test": "ng test --no-watch --no-progress --code-coverage",
    "test:dev": "ng test --code-coverage --karma-config=karma.conf.dev.js",
    "e2e": "ng e2e",
    "prepare": "husky install",
    "lint": "ng lint",
    "lint-staged": "lint-staged -v",
    "pre-commit": "lint-staged && ng lint",
    "sonar": "sonar-scanner",
    "lint-scss": "stylelint src/**/*.scss --fix",
    "prettier": "prettier --write src/**/*.{ts,scss}"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@ng-bootstrap/ng-bootstrap": "^17.0.1",
    "@ng-select/ng-select": "^13.5.0",
    "@ngrx/effects": "^18.0.1",
    "@ngrx/operators": "^18.0.1",
    "@ngrx/router-store": "^18.0.1",
    "@ngrx/store": "^18.0.1",
    "@ngrx/store-devtools": "^18.0.1",
    "@ngx-formly/core": "^6.3.6",
    "@popperjs/core": "^2.11.8",
    "@rocketfinancialcorp/rocket-ui": "^2.2.0",
    "@sentry/angular": "^8.21.0",
    "@sentry/cli": "^2.33.0",
    "@superset-ui/embedded-sdk": "0.1.3",
    "@swimlane/ngx-datatable": "^20.1.0",
    "@types/google.maps": "^3.55.12",
    "crypto-es": "^2.1.0",
    "d3": "^7.8.2",
    "date-fns": "^3.6.0",
    "date-fns-tz": "^3.1.3",
    "file-saver": "^2.0.5",
    "firebase": "^10.12.4",
    "flag-icons": "7.2.3",
    "karma-sonarqube-unit-reporter": "^0.0.23",
    "libphonenumber-js": "^1.11.5",
    "lodash-es": "^4.17.21",
    "ngrx-store-localstorage": "^18.0.0",
    "ngx-extended-pdf-viewer": "^16.2.15",
    "ngx-mask": "^17.0.8",
    "ngx-pagination": "^6.0.3",
    "ngx-scrollbar": "^10.0.1",
    "ngx-toastr": "^19.0.0",
    "rxjs": "^7.8.1",
    "stylelint-declaration-strict-value": "^1.9.1",
    "tslib": "^2.6.3",
    "typescript": "5.4.5",
    "uuid": "^10.0.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "^18.1.0",
    "@angular-eslint/eslint-plugin": "^18.1.0",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular-eslint/schematics": "^18.1.0",
    "@angular-eslint/template-parser": "^18.1.0",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/language-service": "^18.2.13",
    "@ngrx/eslint-plugin": "^14.3.2",
    "@schematics/angular": "^14.2.10",
    "@types/d3": "^7.4.0",
    "@types/file-saver": "^2.0.5",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "^2.0.9",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^20.14.8",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "8.0.0-alpha.61",
    "@typescript-eslint/parser": "8.0.0-alpha.61",
    "angular-eslint": "18.1.0",
    "eslint": "^8.38.0",
    "eslint-config-prettier": "^8.8.0",
    "eslint-import-resolver-typescript": "^3.7.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-jasmine": "^4.1.3",
    "eslint-plugin-jsdoc": "^50.6.3",
    "eslint-plugin-ngrx": "^2.1.4",
    "eslint-plugin-no-loops": "^0.3.0",
    "eslint-plugin-no-unsanitized": "^4.0.2",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-promise": "^6.1.1",
    "eslint-plugin-regexp": "^1.14.0",
    "husky": "^8.0.2",
    "jasmine-core": "^4.5.0",
    "jasmine-marbles": "^0.9.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-sonarqube-unit-reporter": "0.0.23",
    "karma-spec-reporter": "0.0.36",
    "karma-verbose-reporter": "^0.0.8",
    "lint-staged": "^15.2.7",
    "postcss": "^8.4.40",
    "postcss-scss": "^4.0.9",
    "prettier": "^3.3.3",
    "sonar-scanner": "^3.1.0",
    "sonarqube-scanner": "^2.8.2",
    "stylelint": "^16.8.1",
    "stylelint-config-prettier": "^9.0.5",
    "stylelint-config-standard": "^36.0.1",
    "stylelint-declaration-strict-value": "^1.10.6",
    "stylelint-prettier": "^5.0.2",
    "stylelint-scss": "^6.4.1",
    "tailwindcss": "^3.4.7",
    "ts-node": "~10.9.2",
    "typescript-eslint": "8.0.0-alpha.61"
  },
  "browser": {
    "crypto": false
  },
  "lint-staged": {
    "**/*.{json,md,html}": [
      "prettier --write"
    ],
    "**/*.ts": [
      "prettier --write",
      "eslint"
    ],
    "**/*.scss": [
      "prettier --write",
      "npm run lint-scss"
    ]
  }
}
