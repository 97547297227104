import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ENVIRONMENT_INITIALIZER, Provider, importProvidersFrom, inject } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter, withComponentInputBinding, withHashLocation, withRouterConfig } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormlyModule } from '@ngx-formly/core';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import {
  RktFormCheckboxGroupComponent,
  RktFormDatepickerComponent,
  RktFormFieldWrapperComponent,
  RktFormInputCardComponent,
  RktFormInputComponent,
  RktFormInputPasswordComponent,
  RktFormInputPhoneComponent,
  RktFormInputPlaceComponent,
  RktFormInputSecureComponent,
  RktFormRadioGroupComponent,
  RktFormRepeatComponent,
  RktFormRepeatSectionComponent,
  RktFormSelectComponent,
  RktFormSelectCountryComponent,
  RktFormSelectGroupComponent,
  RktFormSelectMultiComponent,
  RktFormSelectStateComponent,
  RktFormTextareaComponent,
  RktFormTimepickerComponent,
  RktFormlyFieldGroupComponent,
  cardNumberValidator,
  countryCodeValidator,
  minLengthValidationMessage,
  phoneNumberValidator,
  requiredValidationMessage,
  stateCodeValidator,
} from '@rocketfinancialcorp/rocket-ui/form';
import {
  RktIconRegistryService,
  accounting,
  ach,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  attachment,
  bank,
  beneficiary,
  brainstorm,
  briefcase,
  bulb,
  businessman,
  calendar,
  campaign,
  card,
  cashInHand,
  chat,
  check,
  checkboxOff,
  checkboxOn,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  close,
  company,
  copy,
  dashboard,
  depositAccounts,
  dispute,
  document,
  done,
  doubleArrowLeft,
  doubleArrowRight,
  download,
  driverLicense,
  edit,
  error,
  externalLink,
  eye,
  eyeSlash,
  fantasy,
  filter,
  flinksIcon,
  graphReport,
  identificationChecked,
  info,
  insight,
  link,
  list,
  loading,
  location,
  lock,
  mail,
  mailing,
  minusSign,
  moneyBag,
  moneyRequest,
  monitor,
  moveMoney,
  myAccount,
  newDocument,
  note,
  notification,
  passport,
  phone,
  plus,
  profile,
  report,
  request,
  resend,
  resume,
  reverse,
  sameDay,
  save,
  schedule,
  search,
  send,
  shipping,
  star,
  suspend,
  team,
  tiles,
  times,
  transfer,
  trash,
  upload,
  user,
  viewFile,
  wallClock,
} from '@rocketfinancialcorp/rocket-ui/icon';

import { AccessControlGuard, AuthGuard, CanDeactivateGuard, FeatureToggleGuard, NoAuthGuard } from '@shared/guards';
import { ErrorInterceptor, JwtInterceptor, LoaderInterceptor, WatcherInterceptor } from '@shared/interceptors';
import { APP_ENV_CONFIG, LoaderService, getEnvironment, sentryProviders } from '@shared/services';
import {
  AdaRegistrationDetailsEffects,
  AdaRegistrationEffects,
  AggregatedAccountBalanceEffects,
  AskMeAnythingEffects,
  AuditEffects,
  AuthEffects,
  BusinessAccountBeneficiaryEffects,
  CardAccountEffects,
  CustomerBeneficiaryEffects,
  DocumentEffects,
  ExceptionManagementEffects,
  FeatureManagementEffects,
  FinancialAccountsEffects,
  IndustryEffects,
  InsightDocumentEffects,
  MessagesEffects,
  ModalsEffects,
  ROOT_REDUCERS,
  RecipientEffects,
  ReportEffects,
  TransactionFormEffects,
  TransactionSolutionEffects,
  TransactionsEffects,
  ewaFeature,
  exceptionManagementFeature,
  featureManagementFeature,
  financialAccountFeature,
  fromAdaRegistration,
  fromAggregatedAccountBalance,
  fromAskMeAnything,
  fromAudit,
  fromAuth,
  fromBusinessAccount,
  fromCustomer,
  fromDocument,
  fromFinancialAccount,
  fromIndustries,
  fromInsightDocument,
  fromRecipient,
  fromReport,
  fromTransaction,
  linxFeature,
  metaReducers,
  recipientsFeature,
  transactionBatchFeature,
  transactionFeature,
  transactionFormFeature,
} from '@shared/store';
import { SetPageTitle, getAppBaseHref } from '@shared/utils';

import { APP_ROUTES } from './portal-client-app.routes';

interface RktIcon<T> {
  name: T;
  data: string;
}

const clock: RktIcon<'clock'> = {
  name: 'clock',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21"><path fill="currentColor" d="M10.5 3C6.35775 3 3 6.35775 3 10.5c0 4.1423 3.35775 7.5 7.5 7.5 4.1423 0 7.5-3.3577 7.5-7.5C18 6.35775 14.6423 3 10.5 3Zm2.4698 11.0303L9.75 10.8105V6h1.5v4.1895l2.7803 2.7803-1.0605 1.0605Z"/></svg>`,
};

const exceptionsList: RktIcon<'exceptions-list'> = {
  name: 'exceptions-list',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="currentColor" d="m5.586 3-2 2H2v2h2.414L7 4.414 5.586 3ZM9 5v2h13V5H9ZM5.586 9l-2 2H2v2h2.414L7 10.414 5.586 9ZM9 11v2h13v-2H9Zm-5 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm5 .5v2h13v-2H9Z"/></svg>`,
};

const attachmentImg: RktIcon<'attachment-img'> = {
  name: 'attachment-img',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="currentColor" d="M16.667 3.333H3.334c-.921 0-1.667.746-1.667 1.667v10c0 .92.746 1.666 1.667 1.666h13.333c.92 0 1.667-.745 1.667-1.666V5c0-.921-.746-1.667-1.667-1.667ZM8.334 6.666a.834.834 0 1 1 0 1.668.834.834 0 0 1 0-1.668Zm6.666 7.5H5.02a.416.416 0 0 1-.329-.672l2.075-2.668a.416.416 0 0 1 .65-.01l1.752 2.109 2.582-3.327a.416.416 0 0 1 .662.006l2.923 3.896a.417.417 0 0 1-.334.666Z"/></svg>`,
};

const attachmentPdf: RktIcon<'attachment-pdf'> = {
  name: 'attachment-pdf',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="currentColor" d="M5.556 1c-.973 0-1.778.815-1.778 1.8v4.5C2.796 7.3 2 8.105 2 9.1v4.5c0 .995.796 1.8 1.778 1.8v1.8c0 .985.805 1.8 1.778 1.8h10.666c.973 0 1.778-.815 1.778-1.8V5.873a.906.906 0 0 0-.26-.637l-3.924-3.972A.884.884 0 0 0 13.187 1H5.556Zm0 1.8h7.11v2.7c0 .497.399.9.89.9h2.666v.9H5.556V2.8ZM3.778 9.1H5.11c.736 0 1.333.605 1.333 1.35 0 .745-.597 1.35-1.333 1.35h-.444v1.8h-.89V9.1Zm8 0h2.666v.9h-1.777v.9h1.421v.9h-1.421v1.8h-.89V9.1Zm-4.445.021h1.448c1.406 0 2.108.856 2.108 1.895v.694c0 1.052-.703 1.89-2.12 1.89H7.333V9.121ZM4.667 10v.9h.444a.447.447 0 0 0 .445-.45c0-.248-.2-.45-.445-.45h-.444Zm3.555.021V12.7h.547c.562 0 1.231-.172 1.231-.99v-.694c0-.823-.662-.995-1.219-.995h-.559ZM5.556 15.4h10.666v1.8H5.556v-1.8Z"/></svg>`,
};

const refresh: RktIcon<'refresh'> = {
  name: 'refresh',
  data: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="currentColor" d="M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8h-1.6c0 3.529-2.871 6.4-6.4 6.4A6.407 6.407 0 0 1 5.6 12c0-3.529 2.871-6.4 6.4-6.4 1.765 0 3.362.72 4.52 1.88L14.4 9.6H20V4l-2.352 2.352A7.955 7.955 0 0 0 12 4Z"/></svg>',
};

const verified: RktIcon<'verified'> = {
  name: 'verified',
  data: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path fill="currentColor" d="m20.305 12 .56-1.728a2.77 2.77 0 0 0-1.374-3.318l-1.617-.827-.827-1.617a2.768 2.768 0 0 0-3.318-1.374L12 3.695l-1.728-.56a2.77 2.77 0 0 0-3.318 1.374l-.827 1.618-1.618.827a2.77 2.77 0 0 0-1.374 3.318L3.695 12l-.56 1.728a2.77 2.77 0 0 0 1.374 3.318l1.618.827.826 1.617a2.768 2.768 0 0 0 3.318 1.374L12 20.305l1.728.56a2.77 2.77 0 0 0 3.318-1.374l.827-1.617 1.617-.827a2.768 2.768 0 0 0 1.374-3.318L20.305 12ZM11.1 15.973l-3.336-3.337 1.272-1.272 2.064 2.063 4.764-4.763 1.272 1.272-6.036 6.037Z"/></svg>',
};

export const iconsProvider: () => Provider = () => {
  return {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () =>
      inject(RktIconRegistryService).registerIcons([
        info,
        edit,
        arrowLeft,
        filter,
        trash,
        suspend,
        resume,
        bank,
        card,
        plus,
        check,
        times,
        chevronUp,
        chevronDown,
        dashboard,
        moveMoney,
        myAccount,
        team,
        campaign,
        location,
        mail,
        mailing,
        phone,
        profile,
        save,
        moneyBag,
        request,
        resend,
        calendar,
        send,
        moneyRequest,
        ach,
        transfer,
        note,
        close,
        reverse,
        monitor,
        document,
        sameDay,
        user,
        arrowRight,
        lock,
        upload,
        identificationChecked,
        passport,
        driverLicense,
        company,
        accounting,
        businessman,
        done,
        depositAccounts,
        dispute,
        briefcase,
        beneficiary,
        insight,
        cashInHand,
        shipping,
        star,
        minusSign,
        error,
        notification,
        download,
        link,
        copy,
        externalLink,
        bulb,
        attachment,
        eye,
        eyeSlash,
        tiles,
        list,
        brainstorm,
        fantasy,
        graphReport,
        chat,
        clock,
        schedule,
        newDocument,
        report,
        wallClock,
        viewFile,
        exceptionsList,
        attachmentImg,
        attachmentPdf,
        refresh,
        verified,
        star,
        chevronLeft,
        chevronRight,
        doubleArrowLeft,
        doubleArrowRight,
        checkboxOff,
        checkboxOn,
        arrowDown,
        arrowUp,
        search,
        flinksIcon,
        loading,
      ]),
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideEnvironmentNgxMask(),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([JwtInterceptor])),
    importProvidersFrom(
      BrowserModule,
      ToastrModule.forRoot({
        preventDuplicates: true,
      }),
      FormlyModule.forRoot({
        types: [
          { name: 'formly-group', component: RktFormlyFieldGroupComponent },
          { name: 'input', component: RktFormInputComponent, wrappers: ['field-wrapper'] },
          { name: 'input-phone', component: RktFormInputPhoneComponent, wrappers: ['field-wrapper'] },
          { name: 'input-place', component: RktFormInputPlaceComponent, wrappers: ['field-wrapper'] },
          { name: 'input-password', component: RktFormInputPasswordComponent, wrappers: ['field-wrapper'] },
          { name: 'input-card', component: RktFormInputCardComponent, wrappers: ['field-wrapper'] },
          { name: 'input-secure', component: RktFormInputSecureComponent, wrappers: ['field-wrapper'] },
          { name: 'number', extends: 'input', defaultOptions: { props: { type: 'number' } } },
          { name: 'select', component: RktFormSelectComponent, wrappers: ['field-wrapper'] },
          { name: 'select-country', component: RktFormSelectCountryComponent, wrappers: ['field-wrapper'] },
          { name: 'select-state', component: RktFormSelectStateComponent, wrappers: ['field-wrapper'] },
          { name: 'select-multi', component: RktFormSelectMultiComponent, wrappers: ['field-wrapper'] },
          { name: 'textarea', component: RktFormTextareaComponent, wrappers: ['field-wrapper'] },
          { name: 'radio-group', component: RktFormRadioGroupComponent, wrappers: ['field-wrapper'] },
          { name: 'checkbox-group', component: RktFormCheckboxGroupComponent, wrappers: ['field-wrapper'] },
          { name: 'select-group', component: RktFormSelectGroupComponent, wrappers: ['field-wrapper'] },
          { name: 'repeat', component: RktFormRepeatComponent, wrappers: ['field-wrapper'] },
          { name: 'repeat-section', component: RktFormRepeatSectionComponent, wrappers: ['field-wrapper'] },
          { name: 'datepicker', component: RktFormDatepickerComponent, wrappers: ['field-wrapper'] },
          { name: 'timepicker', component: RktFormTimepickerComponent, wrappers: ['field-wrapper'] },
        ],
        wrappers: [{ name: 'field-wrapper', component: RktFormFieldWrapperComponent }],
        validationMessages: [
          { name: 'required', message: requiredValidationMessage },
          { name: 'minLength', message: minLengthValidationMessage },
        ],
        validators: [
          { name: 'isValidPhoneNumber', validation: phoneNumberValidator },
          { name: 'isValidCountryCode', validation: countryCodeValidator },
          { name: 'isValidStateCode', validation: stateCodeValidator },
          { name: 'isValidCardNumber', validation: cardNumberValidator },
        ],
      }),
      StoreModule.forRoot(ROOT_REDUCERS, {
        metaReducers,
        runtimeChecks: {
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }),
      EffectsModule.forRoot([MessagesEffects, ModalsEffects]),
      StoreModule.forFeature(fromRecipient.recipientFeatureKey, fromRecipient.reducers),
      StoreModule.forFeature(fromTransaction.transactionFeatureKey, fromTransaction.reducers),
      StoreModule.forFeature(fromDocument.documentFeatureKey, fromDocument.reducers),
      StoreModule.forFeature(fromCustomer.customerFeatureKey, fromCustomer.reducers),
      StoreModule.forFeature(fromAggregatedAccountBalance.aggregatedAccountBalanceFeatureKey, fromAggregatedAccountBalance.reducers),
      StoreModule.forFeature(fromInsightDocument.insightDocumentFeatureKey, fromInsightDocument.reducers),
      StoreModule.forFeature(fromAskMeAnything.AskMeAnythingStoreKey, fromAskMeAnything.reducers),
      StoreModule.forFeature(fromBusinessAccount.businessAccountFeatureKey, fromBusinessAccount.reducers),
      StoreModule.forFeature(fromFinancialAccount.financialAccountFeatureKey, fromFinancialAccount.reducers),
      StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducers),
      StoreModule.forFeature(fromReport.reportFeatureKey, fromReport.reducers),
      StoreModule.forFeature(transactionFeature),
      StoreModule.forFeature(transactionFormFeature),
      StoreModule.forFeature(featureManagementFeature),
      StoreModule.forFeature(fromAudit.auditFeatureKey, fromAudit.reducers),
      StoreModule.forFeature(fromIndustries.industryFeatureKey, fromIndustries.reducers),
      StoreModule.forFeature(fromAdaRegistration.adaRegistrationFeatureKey, fromAdaRegistration.reducers),
      StoreModule.forFeature(exceptionManagementFeature),
      StoreModule.forFeature(recipientsFeature),
      StoreModule.forFeature(transactionBatchFeature),
      StoreModule.forFeature(ewaFeature),
      StoreModule.forFeature(linxFeature),
      StoreModule.forFeature(financialAccountFeature),
      EffectsModule.forFeature([
        AuthEffects,
        RecipientEffects,
        TransactionSolutionEffects,
        DocumentEffects,
        CustomerBeneficiaryEffects,
        BusinessAccountBeneficiaryEffects,
        CardAccountEffects,
        TransactionsEffects,
        FeatureManagementEffects,
        TransactionFormEffects,
        FinancialAccountsEffects,
        ReportEffects,
        AggregatedAccountBalanceEffects,
        InsightDocumentEffects,
        AuditEffects,
        IndustryEffects,
        AdaRegistrationEffects,
        AdaRegistrationDetailsEffects,
        ExceptionManagementEffects,
        AskMeAnythingEffects,
      ]),
      StoreRouterConnectingModule.forRoot({ navigationActionTiming: NavigationActionTiming.PostActivation }),
      StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: getEnvironment().production }),
    ),
    LoaderService,
    AuthGuard,
    NoAuthGuard,
    FeatureToggleGuard,
    CanDeactivateGuard,
    AccessControlGuard,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: SetPageTitle, deps: [Router, Title], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WatcherInterceptor, multi: true },
    { provide: APP_BASE_HREF, useFactory: getAppBaseHref },
    { provide: APP_ENV_CONFIG, useFactory: getEnvironment },
    ...sentryProviders(),
    iconsProvider(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(APP_ROUTES, withComponentInputBinding(), withHashLocation(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),
  ],
};
