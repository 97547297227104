import { Route } from '@angular/router';

import { AuthGuard, NoAuthGuard } from '@shared/guards';

import { LoadingBarComponent } from './shared';
import { routeNames } from './shared/constants';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () => import('./modules/landing').then((m) => m.LANDING_ROUTES),
    canActivate: [NoAuthGuard],
  },
  {
    path: routeNames.ACCOUNT_AUTH,
    children: [
      {
        path: 'team-member',
        loadChildren: () => import('./modules/landing-employee-auth').then((m) => m.LANDING_EMPLOYEE_AUTH_ROUTES),
      },
    ],
    canActivate: [NoAuthGuard],
  },
  {
    path: 'app',
    loadChildren: () => import('./modules/home').then((m) => m.HOME_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: ':/app',
    component: LoadingBarComponent,
  },
  {
    path: '404',
    loadChildren: () => import('./modules/error-page-not-found').then((m) => m.ERROR_PAGE_ROUTES),
  },
  {
    path: '',
    redirectTo: `app/${routeNames.DASHBOARD}`,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
